// Set the classes based on the system preference or the local storage - include this script in the beginning
const lightThemeClass = "light-theme";
const darkThemeClass = "dark-theme";
const storageKey = "theme";


// Is the system preference dark, true or false
const isDarkSystemTheme =
  window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;

const getSelectedTheme = () => localStorage.getItem(storageKey);

const setDarkModeClasses = () => {
  document.body.classList.add(darkThemeClass);
  document.body.classList.remove(lightThemeClass);

};

const setLightModeClasses = () => {
  document.body.classList.add(lightThemeClass);
  document.body.classList.remove(darkThemeClass);
};

const handleSystemThemePreferenceMount = () => {
  isDarkSystemTheme ? setDarkModeClasses() : setLightModeClasses();
};

const handleMount = () => {
  const selectedTheme = getSelectedTheme();

  if (!selectedTheme) {
    return handleSystemThemePreferenceMount();

  } else {
    if (selectedTheme === darkThemeClass) {
      setDarkModeClasses();
    } else {
      setLightModeClasses();
    }
  }
};
handleMount();
